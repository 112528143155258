import { Select } from 'antd';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, memo } from 'react';
import Item from './Item';

const { Option } = Select;

const SelectField = ({
    name,
    label,
    validate,
    fieldOptions = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
    getKey = (item, index) => (item instanceof Object && item.id) || index.toString(),
    getValue = item => item,
    renderOption = item => item,
    options,
    parse = value => value,
    format = rawValue => rawValue,
    required,
    ...props
}) => {
    const [field, meta, helpers] = useField({ name, validate });
    const { value } = field;
    const { setTouched, setValue } = helpers;

    const onSelect = useCallback(
        newValue => {
            setValue(parse(newValue));
        },
        [setValue, parse]
    );

    const onBlur = useCallback(() => {
        setTouched(true);
    }, [setTouched]);

    return (
        <Item label={label} meta={meta} name={name} required={required}>
            <Select
                name={name}
                {...props}
                onBlur={onBlur}
                onChange={onSelect}
                options={options.map((item, index) => ({
                    label: renderOption(item, index),
                    value: format(getValue(item)),
                    disabled: item?.disabled || false,
                }))}
                required={required}
                value={format(value)}
            />
        </Item>
    );
};

SelectField.propTypes = {
    fieldOptions: PropTypes.shape({}),
    format: PropTypes.func,
    getKey: PropTypes.func,
    getValue: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    parse: PropTypes.func,
    renderOption: PropTypes.func,
    required: PropTypes.bool,
    validate: PropTypes.func,
};

export default memo(SelectField);
