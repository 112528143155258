import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const createClient = () => {
    const httpLink = createUploadLink({
        uri: '/graphql',
        fetchOptions: { credentials: 'include' },
    });

    return new ApolloClient({
        uri: '/graphql',
        cache: new InMemoryCache(),
        link: httpLink,
    });
};

const ApolloClientProvider = ({ children }) => {
    // instance the apollo client
    const client = useMemo(() => createClient(), []);

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;

ApolloClientProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
