import { InputNumber } from 'antd';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import Item from './Item';

const NumberField = ({ required, name, validate, label, extra, ...props }) => {
    const [field, meta, helpers] = useField({ name, validate });
    const { setTouched, setValue } = helpers;

    const onChangeEnhanced = useCallback(
        value => {
            setValue(value);
            setTouched(true);
        },
        [setValue, setTouched]
    );

    return (
        <Item extra={extra} label={label} meta={meta} name={name} required={required}>
            <InputNumber
                required={required}
                {...props}
                {...field}
                defaultValue={meta.value}
                onChange={onChangeEnhanced}
                parser={value => {
                    if (value.length === 0) {
                        return '0';
                    }

                    return value.replace(/\D+/g, '');
                }}
            />
        </Item>
    );
};

NumberField.propTypes = {
    extra: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    validate: PropTypes.func,
};

export default memo(NumberField);
