import { UserOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Result, Form } from 'antd';
import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { wrapPromise } from '../../../utils/nprogress';
import * as fields from '../LoginForm/fields';
import { SubmitButton } from '../lib-formik';
import { requestMutation } from './api.graphql';
import style from './style.module.css';

const useSubmit = () => {
    const [done, setDone] = useState(false);
    const client = useApolloClient();

    const callback = useCallback(
        async values => {
            const promise = client.mutate({ mutation: requestMutation, variables: values });
            await wrapPromise(promise);
            setDone(true);
        },
        [setDone, client]
    );

    return [done, callback];
};

const ResetPasswordForm = () => {
    const { t } = useTranslation(['public']);
    const [done, onSubmit] = useSubmit();

    if (done) {
        return (
            <Result
                status="success"
                subTitle={t('public:messages.newResetPassword.description')}
                title={t('public:messages.newResetPassword.title')}
            />
        );
    }

    return (
        <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit }) => (
                <Form onFinish={handleSubmit}>
                    <fields.EmailField placeholder={t('public:fields.email')} prefix={<UserOutlined />} />
                    <Form.Item>
                        <SubmitButton className={style.submitBtn} type="primary">
                            {t('public:ui.newResetPasswordSubmitButton')}
                        </SubmitButton>
                    </Form.Item>
                </Form>
            )}
        </Formik>
    );
};

export default ResetPasswordForm;
