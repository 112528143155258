import { Button } from 'antd';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const SubmitButton = ({ itemProps, ...props }) => {
    const context = useFormikContext();
    const { isSubmitting } = context;

    return <Button type="primary" {...props} htmlType="submit" loading={isSubmitting} />;
};

SubmitButton.propTypes = {
    itemProps: PropTypes.shape({}),
};

export default SubmitButton;
