import { InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Hint = ({ children }) => {
    const { t } = useTranslation(['common']);

    return (
        <>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('common:uploadFieldText')}</p>
            {children && <p className="ant-upload-hint">{children}</p>}
        </>
    );
};

Hint.propTypes = {
    children: PropTypes.node,
};

export default Hint;
