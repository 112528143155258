import { DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSession } from '../../../contexts/SessionContext';
import style from './File.module.less';

const getIcon = type => {
    switch (type) {
        default:
            return <FileOutlined />;
    }
};

const getCleanName = file => file.name.replace(/_/g, ' ');

const File = ({ file, onRemove, renderActions, renderInner, index }) => {
    const handleRemove = useCallback(() => onRemove(file), [onRemove, file]);
    const { permissions } = useSession();

    return (
        <div className={style.file}>
            <div className={style.type}>{getIcon(file.type)}</div>
            <div className={style.inner} title={getCleanName(file)}>
                {renderInner ? renderInner(file) : <span>{getCleanName(file)}</span>}
            </div>
            {!(file.docType === 'confidential' && !permissions.mayUseConfidential) && (
                <div className={style.actions}>
                    {renderActions && renderActions(file, index)}

                    {onRemove && (
                        <Button className={style.removeBtn} icon={<DeleteOutlined />} onClick={handleRemove} />
                    )}
                </div>
            )}
        </div>
    );
};

File.propTypes = {
    file: PropTypes.shape({
        docType: PropTypes.string,
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
    }).isRequired,
    index: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
    renderActions: PropTypes.func,
    renderInner: PropTypes.func,
};

export default File;
