import { mergeDeepRight } from 'ramda';

export class HttpError extends Error {
    constructor(response) {
        super(response.statusText);

        this.status = response.status;
        this.response = response;
    }
}

const defaultOptions = {
    // fetch options
    credentials: 'include',
    headers: {},
    // process options
    processHeaders: headers => headers,
    jsonify: true, // automatically parse JSON on successful response
    // error handling
    handleError: error => error, // hook to transform the error before being thrown, return null avoid it
    throwOnError: true, // throw an error whenever the response is not OK (as long as handleError does not return null)
};

const factory = (method, customFactoryOptions = {}) => {
    const factoryOptions = mergeDeepRight(defaultOptions, customFactoryOptions);

    const customFetch = async (url, body = null, customOptions = {}) => {
        const options = mergeDeepRight(factoryOptions, customOptions);

        const fetchOptions = {
            method,
            headers: { ...options.headers },
            credentials: options.credentials,
        };

        if (body) {
            if (body instanceof FormData) {
                fetchOptions.body = body;
            } else {
                fetchOptions.body = JSON.stringify(body);
                fetchOptions.headers['Content-Type'] = 'application/json';
            }
        }

        // process headers
        fetchOptions.headers = options.processHeaders(fetchOptions.headers);

        const response = await fetch(url, fetchOptions);

        if (!response.ok) {
            const error = options.handleError(new HttpError(response));

            if (options.throwOnError && error !== null) {
                throw error;
            }
        }

        if (options.jsonify) {
            return 204 !== response.status ? response.json() : null;
        }

        return response;
    };

    customFetch.overrideOptions = newOptions => factory(method, mergeDeepRight(factoryOptions, newOptions));

    return customFetch;
};

const injectCSRFToken = headers => ({
    ...headers,
    // 'X-CSRFToken': getCookie('csrftoken'),
});

export const GET = factory('GET');
export const POST = factory('POST', { processHeaders: injectCSRFToken });
export const PUT = factory('PUT', { processHeaders: injectCSRFToken });
export const PATCH = factory('PATCH', { processHeaders: injectCSRFToken });
export const DELETE = factory('DELETE', { processHeaders: injectCSRFToken });
