import { LoginOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AccountExistingResult = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(['public']);

    return (
        <Result
            extra={[
                <Button key="login" icon={<LoginOutlined />} onClick={() => navigate('/')} type="primary">
                    {t('public:ui.logInNowButton')}
                </Button>,
            ]}
            status="error"
            subTitle={t('public:messages.accountExisting.description')}
            title={t('public:messages.accountExisting.title')}
        />
    );
};

export default AccountExistingResult;
