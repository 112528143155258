import React from 'react';
import styles from './Background.module.css';

const Background = () => (
    <>
        <div className={styles.background} />
        {/* <div className={styles.backgroundTrap} /> */}
    </>
);

export default Background;
