import { Form } from 'antd';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const getValidateStatus = (help, meta, formContext) => {
    const { touched, error } = meta;
    const { submitCount } = formContext;

    if (submitCount) {
        if (error) {
            return ['error', error];
        }

        return ['success', help];
    }

    if (touched && error) {
        return ['error', error];
    }

    return [undefined, help];
};

const Item = ({ meta, children, help: defaultHelp, ...props }) => {
    const formContext = useFormikContext();
    const [validateStatus, help] = getValidateStatus(defaultHelp, meta, formContext);

    return (
        <Form.Item help={help} validateStatus={validateStatus} {...props}>
            {children}
        </Form.Item>
    );
};

Item.propTypes = {
    children: PropTypes.node.isRequired,
    help: PropTypes.string,
    meta: PropTypes.shape({}).isRequired,
};

export default Item;
