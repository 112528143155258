import { LogoutOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Menu, Avatar, Dropdown } from 'antd';
import React, { useCallback, useMemo } from 'react';
import * as nprogress from '../../../utils/nprogress';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../../contexts/SessionContext';
import { logoutMutation, generateTokenMutation } from './api.graphql';

const { Item } = Menu;

export const useLogout = ({ update }) => {
    const navigate = useNavigate();
    const client = useApolloClient();

    return useCallback(() => {
        const promise = client.mutate({ mutation: logoutMutation }).then(() => {
            update(null);
            navigate('/');
            navigate(0);
        });

        // listen to the promise with nprogress
        nprogress.wrapPromise(promise);
    }, [client, update, history]);
};

const useChangePassword = () => {
    const navigate = useNavigate();
    const client = useApolloClient();

    return useCallback(() => {
        const promise = client.mutate({ mutation: generateTokenMutation }).then(({ data }) => {
            const { generateToken } = data;
            navigate(`/updatePassword/${generateToken?.token}`);
        });

        // listen to the promise with nprogress
        nprogress.wrapPromise(promise);
    }, [client, navigate]);
};
const useOverlay = session => {
    const logout = useLogout(session);
    const generateToken = useChangePassword();

    return useMemo(
        () => (
            <Menu>
                <Item key="logout" onClick={logout}>
                    <LogoutOutlined /> logout
                </Item>
            </Menu>
        ),
        [generateToken, logout]
    );
};

const UserMenu = () => {
    const session = useSession();
    const overlay = useOverlay(session);

    return (
        <Dropdown dropdownRender={() => overlay}>
            <span>
                <Avatar
                    alt="avatar"
                    gap={4}
                    size="large"
                    style={{
                        backgroundColor: '#5B3073',
                        color: 'azure',
                        verticalAlign: 'middle',
                    }}
                >
                    {`${session?.user.firstName[0]}${session?.user.lastName[0]}`.toUpperCase()}
                </Avatar>
            </span>
        </Dropdown>
    );
};

export default UserMenu;
