import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';

const Context = createContext({});

export const useOptions = () => useContext(Context);

const OptionProvider = ({ options, children }) => {
    const enhancedOptions = useMemo(() => {
        const enhancedItems = Object.entries(options).map(([key, items]) => [
            key,
            {
                data: items,
                entries: items.map(item => [item.value, item.label]),
                getLabel: value => items.find(item => item.value === value)?.label,
            },
        ]);

        return Object.fromEntries(enhancedItems);
    }, [options]);

    return <Context.Provider value={enhancedOptions}>{children}</Context.Provider>;
};

OptionProvider.propTypes = {
    children: PropTypes.node.isRequired,
    options: PropTypes.shape({}).isRequired,
};

export default OptionProvider;
