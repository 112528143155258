import { Switch } from 'antd';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Item from './Item';

const SwitchField = ({ name, label, validate, required, helpText, ...props }) => {
    const [field, meta, helpers] = useField({ name, validate });
    const { value } = field;
    const { setTouched, setValue } = helpers;

    const onChange = useCallback(
        newValue => {
            setTouched(true);
            setValue(newValue);
        },
        [setTouched, setValue]
    );

    return (
        <Item help={<small>{helpText}</small>} label={label} meta={meta} name={name} required={required}>
            <Switch checked={value} onChange={onChange} {...props} />
        </Item>
    );
};

SwitchField.propTypes = {
    helpText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    validate: PropTypes.func,
};

export default SwitchField;
