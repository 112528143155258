import PropTypes from 'prop-types';
import React from 'react';
import NewRequest from './NewRequest';
import Update from './Update';

const ResetPasswordForm = ({ token }) => (token ? <Update token={token} /> : <NewRequest />);

ResetPasswordForm.propTypes = {
    token: PropTypes.string,
};

export default ResetPasswordForm;
