import { Input } from 'antd';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Item from './Item';

const InputField = ({ component: Component = Input, required, name, validate, label, ...props }) => {
    const [field, meta] = useField({ name, validate });

    return (
        <Item label={label} meta={meta} name={name} required={required}>
            <Component required={required} {...props} {...field} />
        </Item>
    );
};

InputField.propTypes = {
    component: PropTypes.shape({}),
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    validate: PropTypes.func,
};

export default memo(InputField);
