import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redirect } from 'react-router-dom';
import * as nprogress from '../../utils/nprogress';
import { useLogout } from '../layouts/AppLayout/UserMenu';
import CenteredLayout from '../layouts/CenteredLayout';
import { fetchSession } from './api.graphql';
import { useHttp } from './HttpContext';

// Create a context for the session data
const SessionContext = createContext(null);

/**
 * Provides session context to its children, fetching session data on mount,
 * and managing session state and user language.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The children components.
 * @returns {JSX.Element} The session provider component.
 */
export const SessionProvider = ({ children }) => {
    const client = useApolloClient(); // Apollo Client for GraphQL queries
    const { i18n } = useTranslation(); // i18n for language translation
    const { GET } = useHttp(); // HTTP GET method from HttpContext
    const [data, setData] = useState({}); // State to store session data

    // Memoize the session context value
    const context = useMemo(
        () => ({
            ...data,
            update: setData,
        }),
        [data, setData]
    );

    const logout = useLogout(context); // Logout function

    useEffect(() => {
        // Fetch session data on mount
        const promise = client
            .query({ query: fetchSession, fetchPolicy: 'network-only' })
            .then(({ data: response }) => {
                const { session } = response;
                let newSession;
                if (session) {
                    // Map session feature flags
                    newSession = {
                        ...session,
                        featureFlags: Object.assign(
                            {},
                            ...session.featureFlags.map(x => ({ [x.machineKey]: x.available }))
                        ),
                    };
                } else {
                    newSession = session;
                }
                setData(newSession || {});
            });

        // Listen to the promise with nprogress
        nprogress.wrapPromise(promise);
    }, [client, setData]);

    const userLanguage = data && data.user && data.user.language;

    useEffect(() => {
        // Update i18n language if user language changes
        if (userLanguage && userLanguage !== i18n.language) {
            i18n.changeLanguage(userLanguage);
        }
    }, [i18n, userLanguage]);

    useEffect(() => {
        // Redirect if data contains a redirectTo field
        if (data?.redirectTo) {
            redirect(redirectTo);
        }
    }, [data, redirect]);

    useEffect(() => {
        // Periodically check if the user is still logged in
        if (Object.keys(data).length !== 0) {
            GET(`/api/users/is_logged/`).catch(err => {
                if (err.status === 401) {
                    logout();
                }
            });
        }
    }, [data]);

    // Render loading layout if data is null
    if (data === null) {
        return <CenteredLayout />;
    }

    // Provide session context to children
    return <SessionContext.Provider value={context}>{children}</SessionContext.Provider>;
};

SessionProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

/**
 * Custom hook to use session context.
 *
 * @returns {Object} The session context value.
 */
export const useSession = () => useContext(SessionContext);

export default SessionContext;
