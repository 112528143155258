import React from 'react';
import { validators, InputField } from '../../lib-formik';

const fieldProps = {
    name: 'email',
    validate: validators.required('Please enter your email'),
};

const EmailField = props => <InputField {...props} {...fieldProps} />;

export default EmailField;
