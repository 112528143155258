import React from 'react';
import { validators, InputField } from '../../lib-formik';

const fieldsProps = {
    name: 'firstName',
    validate: validators.required('Please enter a first name'),
};

const FirstNameField = props => <InputField {...props} {...fieldsProps} />;

export default FirstNameField;
