import { LockOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Form } from 'antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { wrapPromise } from '../../../utils/nprogress';
import { setErrorFromDjango, SubmitButton } from '../lib-formik';
import AccountCreatedResult from './AccountCreatedResult';
import AccountExistingResult from './AccountExistingResult';
import { createAccountMutation } from './api.graphql';
import * as fields from './fields';

const useOnSubmit = (token, setResultCard) => {
    const client = useApolloClient();

    return useCallback(
        async (values, form) => {
            const variables = { token, input: values };
            const promise = client.mutate({ mutation: createAccountMutation, variables });
            const { data } = await wrapPromise(promise);
            const { errors, invitationExpired, accountAlreadyCreated, accountCreated } = data.results;

            if (invitationExpired) {
                // do nothing, it shouldn't happen
                return;
            }

            if (accountAlreadyCreated) {
                // display a message
                setResultCard(() => AccountExistingResult);

                return;
            }

            if (accountCreated) {
                // display a message
                setResultCard(() => AccountCreatedResult);

                return;
            }

            setErrorFromDjango(errors, form);
        },
        [client, token, setResultCard]
    );
};

const CreateAccountForm = ({ token, setResultCard }) => {
    const { t } = useTranslation(['public']);
    const onSubmit = useOnSubmit(token, setResultCard);

    return (
        <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit }) => (
                <Form onFinish={handleSubmit}>
                    <fields.FirstNameField placeholder={t('public:fields.firstName')} />
                    <fields.LastNameField placeholder={t('public:fields.lastName')} />
                    <div className="text-right">
                        <SubmitButton>{t('public:ui.joinSubmitButton')}</SubmitButton>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

CreateAccountForm.propTypes = {
    setResultCard: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
};

export default CreateAccountForm;
