import { Card, Divider, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import FadeInDown from '../../animations/FadeInDown';
import CreateAccountForm from '../../forms/CreateAccountFormV2';
import { useQueryWithProgress } from '../../hooks';
import CenteredLayout from '../../layouts/CenteredLayout';
import LoadingResult from '../../ui/LoadingResult';
import MsalButton from '../../ui/MsalButton';
import { fetchInvitation } from './api.graphql';
import styles from './style.module.css';

const { Paragraph } = Typography;

const Join = () => {
    const params = useParams();
    const { token } = params;
    const { t } = useTranslation(['public']);
    const variables = { token };
    const { data, loading } = useQueryWithProgress(fetchInvitation, { fetchPolicy: 'network-only', variables });
    const invitation = data?.invitation;

    const [ResultCard, setResultCard] = useState(null);

    if (!invitation && !loading) {
        return <Navigate to="/" />;
    }

    let element = loading ? (
        <LoadingResult />
    ) : (
        <>
            <Paragraph className={styles.description} type="secondary">
                {t('public:ui.joinDescription', { auditFirm: invitation.firmName })}
            </Paragraph>
            <MsalButton />
            <Divider>{t('public:ui.or')}</Divider>
            <CreateAccountForm setResultCard={setResultCard} token={token} />
        </>
    );

    if (ResultCard) {
        element = <ResultCard />;
    }

    return (
        <CenteredLayout>
            <FadeInDown>
                <Card title={ResultCard ? null : t('public:ui.joinTitle')}>{element}</Card>
            </FadeInDown>
        </CenteredLayout>
    );
};

export default Join;
