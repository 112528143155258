import { Radio } from 'antd';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, memo } from 'react';
import Item from './Item';

const { Group } = Radio;

const RadioField = ({
    name,
    label,
    validate,
    getKey,
    getValue,
    renderOption,
    options,
    radioComponent,
    radioProps,
    required,
    ...props
}) => {
    const [field, meta, helpers] = useField({ name, validate });
    const { value, onChange } = field;
    const { setTouched } = helpers;

    const onChangeEnhanced = useCallback(
        event => {
            onChange(event);
            setTouched(true);
        },
        [onChange, setTouched]
    );

    // todo: assignment in the destructor seems to see Radio as undefined, it shouldn't, got to look into it
    const RadioComponent = radioComponent || Radio;

    return (
        <Item label={label} meta={meta} required={required}>
            <Group name={name} onChange={onChangeEnhanced} value={value} {...props}>
                {options.map((item, index) => (
                    <RadioComponent key={getKey(item, index)} value={getValue(item)} {...radioProps}>
                        {renderOption(item, index)}
                    </RadioComponent>
                ))}
            </Group>
        </Item>
    );
};

RadioField.propTypes = {
    fieldOptions: PropTypes.shape({}),
    getKey: PropTypes.func,
    getValue: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    radioComponent: PropTypes.elementType,
    radioProps: PropTypes.shape({}),
    renderOption: PropTypes.func,
    required: PropTypes.bool,
    validate: PropTypes.func,
};

RadioField.defaultProps = {
    getKey: (item, index) => (item instanceof Object && item.id) || index.toString(),
    getValue: item => item,
    renderOption: item => item,
};

export default memo(RadioField);
