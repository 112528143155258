import nprogress from 'nprogress';

// the count number is zero by default
let count = 0;

export const start = () => {
    // increment the count by one
    count += 1;

    if (count === 1) {
        // start nprogress
        nprogress.start();
    }
};

export const done = () => {
    // decrease the count by one
    count -= 1;

    if (count === 0) {
        // stop nprogress
        nprogress.done();
    }
};

export const wrapPromise = promise => {
    // first start it
    start();

    return promise
        .then(result => {
            // stop nprogress
            done();

            // pass the result back
            return result;
        })
        .catch(error => {
            // stop nprogress
            done();

            // pass the error back
            return Promise.reject(error);
        });
};
