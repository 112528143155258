import React from 'react';
import { validators, InputField } from '../../lib-formik';

const fieldsProps = {
    name: 'lastName',
    validate: validators.required('Please enter a last name'),
};

const LastNameField = props => <InputField {...props} {...fieldsProps} />;

export default LastNameField;
