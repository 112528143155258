import { format as dateFormat } from 'date-fns';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import Fetch from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';

const config = i18n
    .use(Backend)
    .use(detector)
    .use(initReactI18next)
    .init({
        backend: {
            backends: [Fetch, Fetch],

            // backendOptions: [
            //     {
            //         loadPath: `/assets/locales/extractedTranslations/{{lng}}/{{ns}}.json`,
            //     },
            //     {
            //         loadPath: '/extractedTranslations/{{lng}}/{{ns}}.json',
            //     },
            // ],
            cacheHitMode: 'refreshAndUpdateStore',
        },
        // ns: ['common'],
        defaultNS: 'common',
        fallbackLng: 'en',
        load: 'languageOnly',
        debug: import.meta.env.DEV,
        returnEmptyString: false,
        interpolation: {
            escapeValue: false,
            format: (value, format) => {
                if (value instanceof Date) {
                    return dateFormat(value, format);
                }

                return value;
            },
        },
    });

export default config;
