import { Upload } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import File from './File';
import Hint from './Hint';

const { Dragger } = Upload;

const Input = ({ hint, onRemove, fileProps, fileList = [], multiple, ...props }) => (
    <>
        {(multiple || !fileList.length) && (
            <Dragger {...props} fileList={fileList} multiple={multiple}>
                <Hint>{hint}</Hint>
            </Dragger>
        )}
        {fileList.map((file, index) => (
            <File {...fileProps} key={file.uid} file={file} index={index} onRemove={onRemove} />
        ))}
    </>
);

Input.propTypes = {
    fileList: PropTypes.arrayOf(PropTypes.shape({})),
    fileProps: PropTypes.shape({}),
    hint: PropTypes.node,
    multiple: PropTypes.bool.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default forwardRef((props, ref) => <Input {...props} inputRef={ref} />);
