import { Card } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FadeInDown from '../animations/FadeInDown';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import CenteredLayout from '../layouts/CenteredLayout';
import { useParams } from 'react-router-dom';

const ResetPassword = () => {
    const params = useParams();
    const { token } = params;
    const { t } = useTranslation(['public']);

    return (
        <CenteredLayout>
            <FadeInDown>
                <Card title={t('public:ui.resetPasswordTitle')}>
                    <ResetPasswordForm token={token} />
                </Card>
            </FadeInDown>
        </CenteredLayout>
    );
};

export default ResetPassword;
