import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState, Suspense, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { wrapPromise } from '../utils/nprogress';
import FadeInDown from './animations/FadeInDown';
import { fetchOptions } from './api.graphql';
import OptionProvider from './contexts/OptionProvider';
import CenteredLayout from './layouts/CenteredLayout';
import LoadingBundleCard from './ui/LoadingBundleCard';

const AppLoader = props => {
    const { i18n } = useTranslation();

    // loading state
    const [isLoading, setIsLoading] = useState(true);

    // state reference to keep results from the pre-load effect
    const stateRef = useRef({});

    // apollo client is required to fetch options using GraphQL API
    const client = useApolloClient();

    useEffect(() => {
        // set it as loading
        setIsLoading(true);

        // pre-load namespace all at once
        // public is already (probably) pre-loaded
        // we don't need it anyway for the private routes
        const translationPromise = i18n.loadNamespaces([
            // legacy namespaces
            'auditCase',
            'home',
            'common',
            // new namespaces
            'forms',
            'interfaces',
            'modals',
        ]);

        // options promise
        const optionsPromise = client
            .query({ query: fetchOptions, fetchPolicy: 'network-only' })
            .then(({ data: loadedOptions }) => {
                // update the reference
                stateRef.current.options = loadedOptions;
            });

        // preload component
        const componentPromise = stateRef.current.Component
            ? Promise.resolve()
            : import(/* webpackChunkName: "userRoutes" */ './UserRoutes').then(UserRoutes => {
                  // update the reference
                  stateRef.current.Component = UserRoutes.default;
              });

        const promise = Promise.all([componentPromise, translationPromise, optionsPromise]);

        wrapPromise(promise).then(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.loadNamespaces, i18n.language, setIsLoading, stateRef, client]);

    const fallback = (
        <CenteredLayout>
            <FadeInDown>
                <LoadingBundleCard />
            </FadeInDown>
        </CenteredLayout>
    );

    if (isLoading) {
        return fallback;
    }

    const { Component, options } = stateRef.current;

    return (
        <Suspense fallback={fallback}>
            <OptionProvider options={options}>
                <Component {...props} />
            </OptionProvider>
        </Suspense>
    );
};

export default AppLoader;
