import React from 'react';
import { validators, PasswordField as Field } from '../../lib-formik';

const fieldProps = {
    name: 'password',
    validate: validators.required('Please enter your password'),
};

const PasswordField = props => <Field {...fieldProps} {...props} />;

export default PasswordField;
