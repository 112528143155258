import { resetCaches } from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';
import { animated, useSpring } from '@react-spring/web';

export const spring = {
    from: { opacity: 0, transform: 'translate3d(0, -100%, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    // reset: false,
};

const FadeInDown = ({ children }) => {
    const animation = useSpring(spring);
    return <animated.div style={animation}>{children}</animated.div>;
};

FadeInDown.propTypes = {
    children: PropTypes.node.isRequired,
};

export default FadeInDown;
