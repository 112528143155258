import { Card } from 'antd';
import React from 'react';
import FadeInDown from '../animations/FadeInDown';
import LoginForm from '../forms/LoginFormV2';
import CenteredLayout from '../layouts/CenteredLayout';
import styles from './Login.module.less';
import Logo from '../layouts/AppLayout/iaudit.svg?react';

const Login = () => (
    <CenteredLayout>
        <FadeInDown>
            <Card>
                <div className={styles.logo}>
                    <Logo />
                </div>
                <LoginForm />
            </Card>
        </FadeInDown>
    </CenteredLayout>
);

export default Login;
