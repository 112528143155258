import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHttp } from '../contexts/HttpContext';
import style from './MsalButton.module.less';

const MsalButton = () => {
    const { GET } = useHttp();
    const { t } = useTranslation(['public']);
    const handleOnClick = () => {
        if (import.meta.env.VITE_APP_OFFLINE) {
            console.warn('The webapp front is on OFFLINE mode');
            return null;
        }
        GET('api/msal/login_url')
            .then(response => {
                window.location.href = response.login_url;
            })
            .catch(error => console.error('Error fetching the url', error));
    };

    return (
        <Button className={style.m365Btn} onClick={handleOnClick} type="primary">
            {t('public:ui.connectWithM365')}
        </Button>
    );
};

export default MsalButton;
