import { LockOutlined, LoginOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Button, Result, Form } from 'antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { wrapPromise } from '../../../utils/nprogress';
import { redirect } from 'react-router-dom';
import * as fields from '../LoginForm/fields';
import { SubmitButton } from '../lib-formik';
import { resetMutation } from './api.graphql';
import style from './style.module.css';

const useSubmit = (token, t) => {
    const [done, setDone] = useState(false);
    const client = useApolloClient();

    const callback = useCallback(
        async ({ password, passwordBis }, { setErrors }) => {
            if (password !== passwordBis) {
                setErrors({
                    passwordBis: t('public:errors.passwordsNotMatching'),
                });

                return Promise.reject();
            }

            // try to change the password
            const variables = { token, password };
            const promise = client.mutate({ mutation: resetMutation, variables });
            const { data } = await wrapPromise(promise);
            const { ok, error } = data.result;

            if (ok) {
                // set it as done
                setDone(true);

                return Promise.resolve();
            }

            // notify about it
            setErrors({ password: error });

            return Promise.reject();
        },
        [client, setDone, t, token]
    );

    return [done, callback];
};

const ResetPasswordForm = ({ token }) => {
    const { t } = useTranslation(['public']);
    const [done, onSubmit] = useSubmit(token, t);

    if (done) {
        return (
            <Result
                extra={[
                    <Button key="login" icon={<LoginOutlined />} onClick={() => redirect('/')} type="primary">
                        {t('public:ui.logInNowButton')}
                    </Button>,
                ]}
                status="success"
                subTitle={t('public:messages.resetPassword.description')}
                title={t('public:messages.resetPassword.title')}
            />
        );
    }

    return (
        <Formik initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit }) => (
                <Form onFinish={handleSubmit}>
                    <fields.PasswordField placeholder={t('public:fields.newPassword')} prefix={<LockOutlined />} />
                    <fields.PasswordField
                        name="passwordBis"
                        placeholder={t('public:fields.newPasswordBis')}
                        prefix={<LockOutlined />}
                    />
                    <Form.Item>
                        <SubmitButton className={style.submitBtn} type="primary">
                            {t('public:ui.resetPasswordSubmitButton')}
                        </SubmitButton>
                    </Form.Item>
                </Form>
            )}
        </Formik>
    );
};

ResetPasswordForm.propTypes = {
    token: PropTypes.string.isRequired,
};

export default ResetPasswordForm;
