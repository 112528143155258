/* eslint-disable max-len */
import { useQuery } from '@apollo/client';
import { equals } from 'ramda';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as nprogress from '../utils/nprogress';
import { useHttp } from './contexts/HttpContext';

export const useMemoObject = (factory, objectDependencies) => {
    const previousDependenciesRef = useRef(null);

    if (!equals(previousDependenciesRef.current, objectDependencies)) {
        previousDependenciesRef.current = objectDependencies;
    }

    return useMemo(factory, [previousDependenciesRef.current]);
};

export const useIsMountedRef = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
        };
    }, [isMountedRef]);

    return isMountedRef;
};

/**
 * Custom React hook to execute a query with progress indication.
 *
 * @param {string} query - The query to execute.
 * @param {Object} [options=null] - Additional options for the query execution.
 * @returns {Object} The state object representing the query execution.
 * - loading (boolean): Indicates whether the query is currently loading.
 * - data (any): The data returned by the query.
 * - error (Error): An error object if the query encounters an error.
 * - refetch (function): A function to manually trigger refetching of the query.
 */
export const useQueryWithProgress = (query, options = null) => {
    // Execute the query using useQuery hook from Apollo Client, with fetchPolicy set to 'cache-and-network'
    const state = useQuery(query, { fetchPolicy: 'cache-and-network', ...options });
    const { loading } = state;

    useEffect(() => {
        // If the query is loading, start the progress indicator
        if (loading) {
            nprogress.start();

            // When the component unmounts or loading becomes false, stop the progress indicator
            return () => nprogress.done();
        }

        // If loading is false, return an empty cleanup function
        return () => null;
    }, [loading]); // Run this effect whenever loading state changes

    // Return the state object representing the query execution
    return state;
};

/**
 * Custom React hook to manage a boolean toggle state.
 *
 * @param {boolean} defaultState - The initial state value. Default is false.
 * @returns {[boolean, { key: number, context: any, open: (nextContext: any) => void, close: () => void, toggle: (nextContext: any) => void }]}
 * An array containing the current boolean state value and an object with toggle state details and functions.
 * - value (boolean): The current state value.
 * - key (number): An identifier for tracking state changes.
 * - context (any): Additional context associated with the state.
 * - open (function): A function to set the state to true.
 * - close (function): A function to set the state to false.
 * - toggle (function): A function to toggle the state between true and false.
 */
export const useToggle = (defaultState = false) => {
    const [{ value, key, context }, setState] = useState({
        key: defaultState ? 1 : 0,
        value: defaultState,
        context: null,
    });

    const callbacks = useMemo(
        () => ({
            open: nextContext => setState(state => ({ key: state.key + 1, value: true, context: nextContext })),
            close: () => setState(state => ({ ...state, value: false })),
            toggle: nextContext =>
                setState(state => ({
                    key: state.value ? state.key : state.key + 1,
                    value: !state.value,
                    context: nextContext,
                })),
        }),
        [setState]
    );

    return [value, { key, context, ...callbacks }];
};

export const useInitial = factory => {
    const initialValueRef = useRef(undefined);

    if (initialValueRef.current === undefined) {
        initialValueRef.current = factory();
    }

    return initialValueRef.current;
};

export const useMsalUrl = () => {
    const { GET } = useHttp();
    const [msalLink, setMsalLink] = useState(null);

    useEffect(() => {
        if (!import.meta.env.VITE_APP_OFFLINE) {
            const interval = setInterval(() => {
                GET('api/msal/login_url').then(response => setMsalLink(response.login_url));
            }, 1000);

            return () => clearInterval(interval);
        }

        console.warn('The webapp front is on OFFLINE mode');

        return null;
    });

    return msalLink;
};
