import { LoginOutlined } from '@ant-design/icons';
import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AccountCreatedResult = () => {
    const { t } = useTranslation(['public']);
    const navigate = useNavigate();

    return (
        <Result
            extra={[
                <Button key="login" icon={<LoginOutlined />} onClick={() => navigate('/')} type="primary">
                    {t('public:ui.logInNowButton')}
                </Button>,
            ]}
            status="success"
            subTitle={t('public:messages.accountCreated.description')}
            title={t('public:messages.accountCreated.title')}
        />
    );
};

export default AccountCreatedResult;
