import { WarningOutlined } from '@ant-design/icons';
/* eslint-disable no-unused-vars */
import { Modal, message } from 'antd';
import { useField } from 'formik';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useMemo, useCallback, memo } from 'react';
import Item from '../Item';
import Input from './Input';

const UploadField = ({ name, validate, fieldOptions, defaultDocType = 'other', ...props }) => {
    // extract the multiple props without removing it from the object props
    const { multiple } = props;

    // get context from formik
    const [field, meta, helpers] = useField({ name, validate });
    const { value } = field;
    const { setValue, setTouched } = helpers;

    // provide a very specific callback to remove a file from the list
    const onRemove = useCallback(
        ({ uid }) => {
            const { confirm } = Modal;
            confirm({
                title: i18next.t('modals:deleteConfirmation.title'),
                content: i18next.t('modals:deleteConfirmation.content'),
                icon: <WarningOutlined style={{ color: 'red' }} />,
                onOk() {
                    // first find its index
                    const index = value.findIndex(file => file.uid === uid);

                    // make a copy of the current list
                    const newFileList = [...value];

                    // and remove the element
                    newFileList.splice(index, 1);

                    // update formik context
                    setTouched(true);
                    setValue(newFileList);
                },
                onCancel() {},
                okText: i18next.t('common:delete'),
                cancelText: i18next.t('common:no'),
                okButtonProps: { type: 'danger' },
                cancelButtonProps: { type: 'valid' },
            });
        },
        [value, setValue, setTouched]
    );

    // provide new props for the upload element
    const uploadProps = useMemo(
        () => ({
            onChange: ({ fileList: newFileList }) => {
                const cleanedFiles = newFileList.map(file => {
                    if (!file.docType) {
                        return { ...file, docType: defaultDocType };
                    }

                    return file;
                });
                // connect changes to our state
                setTouched(true);
                setValue(multiple ? cleanedFiles : cleanedFiles.slice(-1));
            },

            showUploadList: false,
        }),
        [setValue, setTouched, multiple, defaultDocType]
    );

    return (
        <Item meta={meta} name={name}>
            <Input {...props} fileList={value} onRemove={onRemove} {...uploadProps} />
        </Item>
    );
};

export const UploadFieldLimitedSize = ({
    name,
    validate,
    fieldOptions,
    maxSize = 10000000000000,
    maxSizeErrorMessage = 'forms:validationMessages.fileTooBig',
    defaultDocType = 'other',
    ...props
}) => {
    // extract the multiple props without removing it from the object props
    const { multiple } = props;

    // get context from formik
    const [field, meta, helpers] = useField({ name, validate });
    const { value } = field;
    const { setValue, setTouched } = helpers;

    // provide a very specific callback to remove a file from the list
    const onRemove = useCallback(
        ({ uid }) => {
            const { confirm } = Modal;
            confirm({
                title: i18next.t('modals:deleteConfirmation.title'),
                content: i18next.t('modals:deleteConfirmation.content'),
                icon: <WarningOutlined color="red" />,
                onOk() {
                    // first find its index
                    const index = value.findIndex(file => file.uid === uid);

                    // make a copy of the current list
                    const newFileList = [...value];

                    // and remove the element
                    newFileList.splice(index, 1);

                    // update formik context
                    setTouched(true);
                    setValue(newFileList);
                },
                onCancel() {},
                okText: i18next.t('common:delete'),
                cancelText: i18next.t('common:no'),
                okButtonProps: { type: 'danger' },
                cancelButtonProps: { type: 'valid' },
            });
        },
        [value, setValue, setTouched]
    );

    // provide new props for the upload element
    const uploadProps = useMemo(
        () => ({
            onChange: ({ fileList: newFileList }) => {
                const cleanedFiles = newFileList
                    .map(file => {
                        if (!file.docType) {
                            return { ...file, docType: defaultDocType };
                        }

                        return file;
                    })
                    .filter(file => file.size < maxSize);

                // connect changes to our state
                setTouched(true);
                setValue(multiple ? cleanedFiles : cleanedFiles.slice(-1));
            },
            beforeUpload: file => {
                if (file.size > maxSize) {
                    message.error(
                        i18next.t(maxSizeErrorMessage, {
                            maxSize: Math.ceil(maxSize / (1000 * 1000)),
                        })
                    );

                    return false;
                }

                return true;
            },
            showUploadList: false,
        }),
        [setValue, setTouched, multiple, defaultDocType]
    );

    return (
        <Item meta={meta} name={name}>
            <Input {...props} fileList={value} onRemove={onRemove} {...uploadProps} />
        </Item>
    );
};

UploadField.propTypes = {
    defaultDocType: PropTypes.string,
    defaultFileList: PropTypes.arrayOf(PropTypes.shape({})),
    fieldOptions: PropTypes.shape({}),
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    validate: PropTypes.func,
};

UploadField.defaultProps = {
    multiple: false,
};

UploadFieldLimitedSize.propTypes = {
    defaultDocType: PropTypes.string,
    defaultFileList: PropTypes.arrayOf(PropTypes.shape({})),
    fieldOptions: PropTypes.shape({}),
    multiple: PropTypes.bool,
    maxSize: PropTypes.number,
    maxSizeErrorMessage: PropTypes.string,
    name: PropTypes.string.isRequired,
    validate: PropTypes.func,
};

UploadFieldLimitedSize.defaultProps = {
    multiple: false,
};

export default memo(UploadField);
