import { Select } from 'antd';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, memo } from 'react';
import Item from './Item';

const { Option } = Select;

const TagField = ({
    mode,
    name,
    label,
    validate,
    fieldOptions,
    getKey,
    getValue,
    renderOption,
    options,
    parse,
    format,
    required,
    ...props
}) => {
    const [field, meta, helpers] = useField({ name, validate });
    const { value } = field;
    const { setTouched, setValue } = helpers;

    const onSelect = useCallback(
        newValue => {
            setValue(parse(newValue));
        },
        [setValue, parse]
    );

    const onBlur = useCallback(() => {
        setTouched(true);
    }, [setTouched]);

    return (
        <Item label={label} meta={meta} name={name} required={required}>
            <Select
                mode={mode}
                name={name}
                {...props}
                onBlur={onBlur}
                onChange={onSelect}
                required={required}
                value={value}
            >
                {options.map((item, index) => (
                    <Option key={getKey(item)} value={item.toString()}>
                        {renderOption(item, index)}
                    </Option>
                ))}
            </Select>
        </Item>
    );
};

TagField.propTypes = {
    fieldOptions: PropTypes.shape({}),
    format: PropTypes.func,
    getKey: PropTypes.func,
    getValue: PropTypes.func,
    label: PropTypes.string,
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    parse: PropTypes.func,
    renderOption: PropTypes.func,
    required: PropTypes.bool,
    validate: PropTypes.func,
};

TagField.defaultProps = {
    format: rawValue => rawValue,
    getKey: item => item.toString(),
    getValue: item => item,
    mode: 'tags',
    parse: value => value,
    renderOption: item => item,
};

export default memo(TagField);
