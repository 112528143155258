import { Card } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingResult from './LoadingResult';

const LoadingBundleCard = () => {
    const { t } = useTranslation(['common']);

    return (
        <Card>
            <LoadingResult title={t('common:bundleLoading')} />
        </Card>
    );
};

export default LoadingBundleCard;
