import equal from 'fast-deep-equal';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const FormikChangeEffect = ({ effect }) => {
    const { values, initialValues } = useFormikContext();
    const previousValuesRef = useRef(initialValues);

    useEffect(() => {
        if (!equal(values, previousValuesRef.current)) {
            previousValuesRef.current = values;
            effect(values);
        }
    }, [values, previousValuesRef, effect]);

    return null;
};

FormikChangeEffect.propTypes = {
    effect: PropTypes.func.isRequired,
};

export default FormikChangeEffect;
