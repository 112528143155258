import { Layout } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import Background from './Background';
import styles from './CenteredLayout.module.css';

const { Content } = Layout;

const CenteredLayout = ({ children }) => (
    <Layout className={styles.layout}>
        <Content>
            <Background />
            <div className={styles.content}>{children}</div>
        </Content>
    </Layout>
);

CenteredLayout.propTypes = {
    children: PropTypes.node,
};

export default CenteredLayout;
